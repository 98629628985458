<template>
    <div>
        <b-tabs>
                <b-card-actions
                title="Filters"
                action-collapse
                >
                    <b-card-text>
                        <b-row>
                            <b-col md="3">
                                <b-form-group
                                    label="Grouped Org"
                                    label-for="company-source-filter"
                                >
                                    <custom-autopopulate
                                        id="company-source-filter"
                                        module="companies"
                                        label="name"
                                        @changed="companyChanged"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    label="Branch(es)"
                                    label-for="branch-source-filter"
                                >
                                    <custom-autopopulate
                                        id="branch-source-filter"
                                        module="branches"
                                        label="name"
                                        @changed="branchesChanged"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    label="Account Access"
                                    label-for="account-access-filter"
                                >
                                    <v-select
                                        id="account-access-filter"
                                        v-model="accountaccessFilter"
                                        label="title"
                                        :options="accountaccessOptions"
                                        :reduce="item => item.id"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card-actions>
                <List
                    model="clients"
                    :can-delete="false"
                    :can-edit="true"
                    :can-view="false"
                    default-sort="id"
                    default-sort-order="desc"
                    :where="memberCondition"
                />
        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import List from '../common/list';
import CustomAutopopulate from '../common/components/CustomAutopopulate';
export default {
    components: {
        BTabs,
        BCardText,
        List,
        BCardActions,
        BRow,
        BCol,
        BFormGroup,
        vSelect,
        CustomAutopopulate,
    },
    data() {
        return {
            totalContacts: 'loading...',
            totalMembers: 'loading...',
            totalLeads: 'loading...',
            totalSubscribers: 'loading...',
            createDateFilter: '',
            leadStatusFilter: '',
            leadSourceFilter: '',
            accountaccessFilter: '',
            memberCondition: {
                where_fields: [],
                where_values: [],
            },
            accountaccessOptions: [
                { id: '1', title: 'Yes' },
                { id: '0', title: 'No' },
            ],
            showOnlyCritical: '0',
            showAtRisk: '0',
        };
    },
    watch: {
        accountaccessFilter(val) {
            if (val) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'allow_account_access',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('allow_account_access')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf('allow_account_access');
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
    },
    // async created() {
    //     this.fetchData();
    // },
    methods: {
        companyChanged(val) {
            if (val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'id',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        branchesChanged(val) {
            if (val > 0) {
                this.memberCondition.where_fields = [
                    ...new Set([
                        ...this.memberCondition.where_fields,
                        'branch_id',
                    ]),
                ];
                this.memberCondition.where_values[
                    this.memberCondition.where_fields.indexOf('branch_id')
                ] = val;
            } else {
                const ind = this.memberCondition.where_fields.indexOf(
                    'branch_id',
                );
                this.memberCondition.where_fields.splice(ind, 1);
                this.memberCondition.where_values.splice(ind, 1);
            }
        },
        refreshList() {
            console.log('called');
        },
    },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.custom-control.custom-checkbox {
    top: 40%;
}
.checkbox-container {
    justify-content: space-around;
    padding: 2rem 0;
}
</style>
